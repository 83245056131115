import IcoDrop from '@/assets/img/icons/ico_drop_down@2x.png';
import Bn1 from '@/assets/img/mission/minsion_bn1@2x.jpg';
import Bn2 from '@/assets/img/mission/minsion_bn2@2x.jpg';
import AccordionComponent, { AccordionItem } from '@/components//Accordion';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import PageComponent from '@/components/PageComponent';
import Section from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import { Tit } from '@/components/Titles';
import { breakpoint } from '@/helpers/BreakpointHelper';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const BoxFlex = styled.ul`
  margin: 0 -8px;
  display: flex;
  align-items: stretch;

  & > li {
    padding: 0 8px;
    width: 25%;

    .box {
      height: 100%;
      padding: 24px 20px 16px;
      border-radius: 19px;
      background-color: #f8f9fa;
      text-align: center;
    }
    ${Tit} {
      margin-bottom: 8px;
    }
  }

  ${breakpoint(`tablet`)} {
    margin: 0 -12px;
    flex-wrap: wrap;
    & > li {
      padding: 0 12px;
      width: 50%;
      margin-bottom: 24px;

      .box {
        margin: 0;
        padding: 32px 15px 20px;
      }
    }
  }
`;
const MissionArticle = styled.article`
  margin-top: 120px;
  h3 {
    ${Tit} {
      letter-spacing: -1.6px;
    }
  }

  .dl-wrap {
    padding-top: 96px;

    dl {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 32px;

      &:last-child {
        margin-bottom: 0;
        margin-top: 64px;
      }

      dt {
        width: 170px;

        ${Tit} {
          display: block;
          text-align: right;
          padding-top: 12px;
          border-top: 12px solid #1cabe2;
        }
      }
      dd {
        width: calc(100% - 160px);
        max-width: 840px;
        padding-left: 20px;
      }
    }

    .round {
      padding: 40px 20px;
      border-radius: 90px;
      background-color: #1cabe2;
      text-align: center;

      &.c2 {
        background-color: #72c9eb;
      }
    }
  }

  ${breakpoint(`tablet`)} {
    .dl-wrap {
      padding-top: 70px;

      dl {
        flex-wrap: wrap;
        margin-bottom: 48px;

        &:last-child {
          margin-top: 0;
        }

        dt {
          width: 120px;

          ${Tit} {
            border-width: 8px;
            padding-top: 16px;
          }
        }
        dd {
          width: 100%;
          max-width: 100%;
          padding: 0;
          margin-top: 24px;
        }
      }

      .round {
        padding: 20px;
        border-radius: 90px;
      }
    }
  }
`;

const Accordion = styled(AccordionComponent)`
  & > li {
    border: 0;

    header {
      border: 1px solid #b7b8ba;
      padding: 24px 32px;
      cursor: pointer;
      position: relative;

      &::after {
        right: 32px;
        background-image: url(${IcoDrop}) !important;
        transform: translate(0, -50%) rotate(180deg);
      }
    }
    &.is-active {
      header {
        &::after {
          transform: translate(0, -50%) rotate(0);
        }
      }
    }
  }
`;
const NavigationButton = styled.div`
  ${Tit} {
    color: #2d2926;
  }
`;

const AccordionBody = styled.div`
  padding: 48px 32px 64px;
  background-color: #f8f9fa;
  color: #2d2926;

  .ver {
    text-align: right;
  }
  .article-wrap {
    margin-top: 48px;

    article {
      ${Tit} {
        display: block;
        text-align: center;
        padding-bottom: 32px;
      }
      margin-bottom: 96px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ${breakpoint(`tablet`)} {
    padding: 24px 20px 64px;
  }
`;

const SectionTop = styled(Section)`
  .header-dec {
    margin-top: 0;
  }

  .bn-flex {
    margin-top: 43px;

    & > li {
      width: 50%;
    }
  }
  ${breakpoint(`tablet`)} {
    .bn-flex {
      & > li {
        width: 100%;
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
`;

const SectionMission = styled(Section)`
  ${SectionHeader} {
    padding-bottom: 64px;
  }
  .box {
    padding: 64px 41px 64px 32px;
    background-color: #f8f9fa;

    .blit-list {
      & > li {
        margin-bottom: 32px;
        font-weight: 700;
        line-height: 1.7;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  ${breakpoint(`tablet`)} {
    padding-bottom: 0 !important;
    ${SectionHeader} {
      padding-bottom: 31px;
    }
    .box {
      margin: 0 -20px;
      padding: 48px 20px;
      .blit-list {
        & > li {
          margin-bottom: 24px;
        }
      }
    }
  }
`;

const Mission: React.FC<PageProps> = ({ location }) => (
  <LayoutWithTitle
    location={location}
    title="미션과 가치"
    description="for every child, UNICEF mission"
  >
    <SectionTop className="by-sub-main-layout">
      <Container>
        <p className="dec">
          <PageComponent id="content1">
            유니세프는 1946년 ‘차별 없는 구호’의 정신으로 ‘모든 어린이가 행복한
            세상’을 위해 설립된 유엔기구입니다. 아동권리협약 실현과
            지속가능발전목표 달성을 목표로 전 세계 어린이의 권리와 복지를 위해
            일합니다.
          </PageComponent>
        </p>

        <ul className="bn-flex flex">
          <li>
            <LinkSafe
              to="/about-us/unicef/mission/convention-on-the-rights-of-the-child"
              className="link-item"
            >
              <Image pcSrc={Bn1} mobileSrc={Bn1} />
            </LinkSafe>
          </li>
          <li>
            <LinkSafe
              to="/about-us/unicef/mission/sustainable-development-goals"
              className="link-item"
            >
              <Image pcSrc={Bn2} mobileSrc={Bn2} />
            </LinkSafe>
          </li>
        </ul>
      </Container>
    </SectionTop>

    <SectionMission className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">유니세프 미션</Tit>
          </h2>
        </SectionHeader>
        <div className="box">
          <ul className="blit-list">
            <li>
              유니세프는 국제연합(UN) 총회로부터 어린이 권리를 보호하고 기본적
              욕구 충족을 보장하며 완전한 잠재력 실현 기회를 제공한다는 사명을
              부여받았습니다.
            </li>
            <li>
              유니세프는 아동권리협약(Convention on the Rights of the Child)에
              기초하여 변하지 않는 윤리 원칙과 국제적 행동 지침으로서 어린이의
              권리를 가장 우선시합니다.
            </li>
            <li>
              유니세프는 어린이의 생존, 발달, 보호가 인간의 성장 과정에 필수적인
              보편적 발달 요소라고 믿습니다.
            </li>
            <li>
              유니세프는 개발도상국을 돕고, “아동 최선의 이익”을 보장하며,
              어린이와 가족에게 필요한 정책과 서비스를 제공하기 위해 정치적
              의지를 활용합니다.
            </li>
            <li>
              유니세프는 전쟁, 재해, 극심한 빈곤, 폭력, 착취에 시달리거나 장애를
              가진 가장 취약한 어린이에 대한 특별한 보호를 보장하기 위해 최선을
              다합니다.
            </li>
            <li>
              유니세프는 긴급 상황에 있는 어린이 권리를 보호합니다. 고통받는
              어린이와 보호자들을 구호하기 위해 유니세프는 신속한 대응을 위한
              고유 자원을 UN 협력 기관, 구호 기관과 공유합니다.
            </li>
            <li>
              유니세프는 당파에 치우치지 않으며, 차별 없이 모든 이들과
              협력합니다. 모든 활동에서 가장 취약한 어린이와 가장 많은 도움이
              필요한 국가를 최우선으로 돕습니다.
            </li>
            <li>
              유니세프는 국가사업을 통해 여성이 동등한 권리를 실현하고 정치,
              사회, 경제 발전에 완전히 참여할 수 있도록 장려합니다.
            </li>
            <li>
              유니세프는 협력 기관과 함께 국제 사회가 정한 지속가능발전목표를
              달성하고 UN 헌장에 명시된 평화와 사회 발전의 비전을 실현하기 위해
              노력합니다.
            </li>
          </ul>
        </div>

        <MissionArticle>
          <h3>
            <Tit size="s2" color="sky">
              유니세프한국위원회는 33개 선진국형 국가위원회가 각 국가에서
              유니세프를 대표하듯이 유니세프의 필수 구성원으로서 한국에서
              유니세프를 대표해 유니세프의 이념을 실현합니다
            </Tit>
          </h3>
          <div className="dl-wrap">
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  유니세프 한국위원회<br />
                  미션
                </Tit>
              </dt>
              <dd>
                <div className="round c1">
                  <Tit size="s3" color="white">
                  모든 어린이의 권리와 행복이 실현되는 세상을 만듭니다.
                  </Tit>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">                  
                  비전
                </Tit>
              </dt>
              <dd>
                <div className="round c2">
                  <Tit size="s3" color="white">
                  To be the First Choice of Donors For Every Child<br />
                  - Child First! First Choice!<br /><br />
                  </Tit>
                  <Tit size="s4-1" color="white">
                  어린이를 위해<br />
                  · 후원자가 가장 먼저 후원하고 싶은 기관<br />
                  · 이해관계자가 가장 협력하고 싶은 기관<br />
                  · 동종기관이 가장 벤치마킹하고 싶은 기관<br />
                  · 동종기관 인재가 가장 일하고 싶은 기관 
                  </Tit>
                </div>
              </dd>
            </dl>
            <dl>
              <dt>
                <Tit size="s4-1" color="sky">
                  핵심가치
                </Tit>
              </dt>
              <dd>
                <BoxFlex>
                  <li>
                    <div className="box">
                      <Tit size="s4" color="sky">
                        Trust·신뢰
                      </Tit>
                      <p className="dec">
                        같은 미션을 위해 함께 일하는 구성원을 믿고, 열린 마음으로 소통하고 협력합니다.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <Tit size="s4" color="sky">
                        Respect·존중
                      </Tit>
                      <p className="dec">
                        서로의 다름을 인정하고 예의를 지킵니다.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <Tit size="s4" color="sky">
                        Integrity·진실성
                      </Tit>
                      <p className="dec">
                        높은 윤리 기준을 정하고, 강직하고 정직하게 지킵니다.
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="box">
                      <Tit size="s4" color="sky">
                        Care·배려
                      </Tit>
                      <p className="dec">
                        서로의 입장을 헤아리고, 모두의 건강한 성장과 더 나은 삶을 위해 서로를 돌봅니다.
                      </p>
                    </div>
                  </li>
                </BoxFlex>
              </dd>
            </dl>
          </div>
        </MissionArticle>
      </Container>
    </SectionMission>

    <Section className="by-sub-main-layout">
      <Container>
        <SectionHeader className="with-desc">
          <h2>
            <Tit size="s1">윤리 강령</Tit>
          </h2>
          <p className="header-dec">
            유니세프한국위원회는 유니세프 국가위원회로서 대한민국에서 유니세프의
            이념과 정신을 성실하게 공식적으로 대변하면서 민간부문으로부터 기금을
            조성하고, 아동권리를 옹호하는 데 주력합니다. 위원회는 관련 법규와
            높은 수준의 도덕과 윤리기준을 준수하여 기금모금 활동을 펼쳐나갑니다.
          </p>
        </SectionHeader>
        <Accordion initialKey="0">
          <AccordionItem
            toggleKey="1"
            renderToggle={(onClick) => (
              <NavigationButton onClick={onClick} className="accordion-opener">
                <Tit size="s4">윤리강령 전문 보기</Tit>
              </NavigationButton>
            )}
          >
            <AccordionBody>
              <div className="ver">
                2011년 7월 22일 제정
                <br />
                2014년 12월 24일 개정
                <br />
                2018년 10월 30일 개정
                <br />
                2019년 3월 12일 개정
              </div>
              <div className="article-wrap">
                <article>
                  <Tit size="s3">전문</Tit>
                  <p className="dec">
                    사단법인 유니세프한국위원회(이하 “위원회”라 한다)는
                    유니세프(UNICEF)의 국가위원회로서 대한민국에서 유니세프의
                    이념과 정신을 성실하게 공식적으로 대변하면서
                    민간부문으로부터 기금을 조성하고, 아동권리를 옹호하는 데
                    주력한다. 위원회는 관련 법규와 높은 수준의 도덕과 윤리기준을
                    준수하여 기금모금 활동을 펼쳐나간다. 기금모금 업무를
                    수행하는 직원은 스스로의 행동과 태도를 통해 언제나 책임 있게
                    유니세프를 대표해야 하며, 후원기금 사용시 후원자에 대해
                    정직함과 명확성을 보여야 한다.
                    <br />
                    <br />
                    위원회는 유니세프헌장이 내세우는 가치와 원칙을 재확인하면서,
                    임·직원의 행동과 태도에 있어 지향해 나가야 할 윤리강령을
                    마련하였다. 위원회의 임·직원 모두는 위원회의 핵심가치가
                    스스로 업무를 수행함에 있어 근간을 이룬다는 점, 자신의 공식
                    임무와 책임을 수행함에 있어 본 윤리강령을 충실히 준수할
                    의무가 있다는 점을 숙지해야 한다. 본 강령의 주요 목적은
                    다음과 같다.
                    <br />
                    <br />
                    - 모든 임·직원으로 하여금 유니세프의 윤리기준과
                    아동권리협약(Convention on the Rights of the Child)을
                    숙지하고 그 지침을 따르도록 한다.
                    <br />
                    - 모든 임·직원으로 하여금 진실성과 전문성을 가지고 위원회와
                    지역사회에 봉사할 수 있도록 하는 데 기여한다.
                    <br />- 모든 임·직원이 자신의 업무를 수행하는 과정에서
                    책임감을 가지고 협력기관, 관계인사, 지역의 구성원, 그리고
                    다른 동료들과 적극적으로 의사소통을 하면서 협력해 나가도록
                    분명한 지침을 제공한다.
                  </p>
                </article>
                <article>
                  <Tit size="s3">윤리강령</Tit>
                  <p className="dec">
                    제1조 (아동권리 보호)
                    <br />
                    ① 위원회의 모든 임·직원은 업무수행에 있어 가장 중요한 책무는
                    아동권리를 보호하고, 모든 아동에게 최선의 이익이 될 수
                    있도록 하는 데 있음을 숙지해야 한다.
                    <br />
                    ② 위원회의 모든 임·직원은 아동의 성(性), 인종, 종교, 장애,
                    사회적 배경 및 기타 어떠한 이유에도 불구하고 아동의 권리를
                    보호해야 한다.
                    <br />
                    ③ 위원회의 모든 임·직원은 유니세프헌장에 명시된 가치를
                    반영하고, 일관되게 행동해야 한다. 특히, 아동권리 보호에 관한
                    옹호의 경우를 포함하여 그들의 공식적인 임무는 아동권리협약을
                    따라야 한다.
                    <br />
                    <br />
                    제2조 (공정한 업무수행)
                    <br />
                    ① 위원회는 임무를 달성함에 있어 가장 높은 수준의 책임과
                    윤리에 입각해야 하며, 결코 비윤리적이거나 위법한 행위에
                    의해서는 아니 된다.
                    <br />
                    ② 위원회의 모든 임·직원은 유니세프의 회원 , 후원자 ,
                    자원봉사자 , 협력사 및 소속 직원의 권리를 존중하면서 서로
                    공정하게 대하여야 한다.
                    <br />
                    ③ 어떠한 임·직원도 고의 , 은폐 , 업무상 지득한 정보의 남용 ,
                    실제 사실의 왜곡, 기타 의도적인 불공정 행위에 의해 타인을
                    부당하게 이용해서는 아니 된다.
                    <br />
                    <br />
                    제3조 (성실성)
                    <br />
                    ① 위원회의 모든 임·직원은 자신의 공식 임무와 위원회의 이해에
                    영향을 미치는 모든 일에 있어 정직 , 진실 , 공평 및 청렴을
                    포함하는 가장 높은 수준의 성실성을 유지해야 한다.
                    <br />
                    ② 모든 임·직원은 자신의 임무를 수행함에 있어 스스로가 취한
                    행동이나 결정에 대한 근거가 있어야 한다.
                    <br />
                    ③ 위원회의 임·직원 각자는 유니세프 본부가 부여하는 방향이
                    합법적이고 합당한 것이라면 그 어느 것이든 따라야 한다.
                    <br />
                    <br />
                    제4조 (전문성)
                    <br />
                    ① 위원회의 모든 임·직원은 위원회의 명성을 드높일 수 있도록
                    항상 전문성을 가지고 행동해야 하며 , 위원회의
                    임·직원으로서의 직위에 부정적 영향을 미칠 수 있는 어떠한
                    행동도 삼가야 한다.
                    <br />
                    ② 위원회의 모든 임·직원은 자신의 전문적 지식과 업무 기술을
                    개선하기 위한 노력을 지속적으로 기울여 나가야 한다.
                    <br />
                    <br />
                    제5조 (이해관계자와의 협력)
                    <br />
                    위원회 모든 임·직원은 정부, 기업, 후원자, 지역의 구성원,
                    다른 비영리단체의 자원봉사자 등 다양한 이해관계자와 더불어
                    아동과 위원회의 최선의 이익을 위해 적극 협력해야 한다.
                    <br />
                    <br />
                    제6조 (이해관계의 충돌)
                    <br />
                    ① 위원회의 모든 임·직원은 유니세프 최선의 이익 실현을 위해
                    책임 있게 행동함에 있어 객관적인 판단을 흐리게 하거나 영향을
                    받을만한 금전적 이익, 기타 이와 유사한 것들을 삼가야 한다.
                    <br />
                    ② 위원회의 모든 임·직원은 이해관계의 충돌이 있음을 알게
                    되거나, 또는 충돌에 관해 의문이 제기될 경우에도 위원회의
                    상급자 또는 사무총장에게 즉시 문의하여야 한다.
                    <br />
                    ③ 이해의 충돌이 발생하는 상황은 다음과 같다.
                    <br />
                    1. 임·직원이 직접 또는 제3자를 통해 계약 체결이나 거래행위를
                    하는 경우
                    <br />
                    2. 임·직원과 위원회 간 금전적인 의무나 권리가 발생하는 경우
                    <br />
                    3. 위원회의 임·직원이 위원회의 임무를 수행함에 있어 내리는
                    판단이 본인, 본인의 가구 또는 특수관계인의 실제 이득이나
                    장래 실현될지 모르는 이득에 의해 영향을 받고 있거나 받을
                    수도 있는 경우
                    <br />
                    4. 임·직원이 자신의 개인적인 이익을 위해 위원회 소유의
                    자산이나 정보를 잘못 사용하는 경우
                    <br />
                    5. 임·직원이 위원회의 이해당사자에게 속한 업무수행을
                    부당하게 방해하는 경우
                    <br />
                    6. 임·직원이 유니세프 취업과 양립되기 어려운 외부 업무에
                    종사하는 경우
                    <br />
                    <br />
                    제7조 (부패 방지)
                    <br />
                    ① 위원회 소속 임·직원의 사기, 절도 또는 부패행위 및 이에
                    관련된 행위는 용납되지 않는다. 위원회 소속 임·직원의
                    뇌물수수 행위는 어떠한 상황에서도 엄격히 금지되고 용인되지
                    않는다.
                    <br />
                    ② 지역사회 구성원, 업무와 유관한 이해당사자, 지역사회의
                    조직, 그리고 이해관계의 충돌이 발생할 수 있는 기타 모든
                    관계인들과 연관이 있는 모든 임·직원들이 금전, 향응, 선물
                    또는 특혜를 받거나 이를 제공하는 행위는 엄격히 금지된다.
                    <br />
                    <br />
                    제8조 (공개와 투명성)
                    <br />
                    ① 위원회의 업무 기록은 정확하고 신뢰성 있게 작성되어야 한다.
                    <br />
                    ② 위원회는 위원회의 인터넷 홈페이지
                    웹사이트(http://www.unicef.or.kr) 상에 나타나 있고
                    이해관계자들에게 물질적으로나 그 밖의 경우에 중요하다고
                    보여지는 모든 정보들을 공개해야 한다.
                    <br />
                    <br />
                    제9조 (위원회의 자원의 사용)
                    <br />
                    ① 위원회와 유니세프의 모든 인적, 물적(금전을 포함함, 이하
                    같음) 자원은 허용된 범위 내에서만 사용되어야 한다.
                    <br />
                    ② 위원회 임·직원은 위원회의 재산을 사용함에 있어 스스로의
                    양심에 따라 행동하여야 하며, 낭비를 삼가야 한다.
                    <br />
                    <br />
                    제10조 (사내행동규범)
                    <br />
                    ① 위원회의 임·직원은 상호 존중과 이해의 바탕 위에서 화목한
                    직장을 이루는데 노력을 경주해야 한다.
                    <br />
                    ② 위원회의 임·직원과 공개적으로 의사소통을 해야 하며, 다른
                    동료들과 필요한 정보를 공유하는 가운데 각종 문의사항에 대해
                    시의성 있게 답변하도록 노력해야 한다.
                    <br />
                    ③ 위원회의 모든 인적, 물적 자원은 공식적인 용도에 한해
                    사용되어야 한다.
                    <br />
                    <br />
                    제11조 (불법행위 금지)
                    <br />
                    위원회의 임·직원은 대한민국 헌법 및 법률뿐만 아니라 이
                    윤리강령에 입각하여 처신하고 불법적 행위에 가담하지 않도록
                    해야만 한다.
                    <br />
                    <br />
                    제12조 (보안유지) &lt;개정 2019.3.12.&gt;
                    <br />
                    ① 위원회의 임·직원은 지득한 내부정보 또는 문서를 공적
                    승인절차 없이 외부에 누설·유출·반출하거나 자신 또는 제3자를
                    위해 사용해서는 아니된다. &lt;개정 2018.10.30.&gt;
                    <br />
                    ② 위원회의 모든 문서, 메모, 계약서 그리고 기타 기록물은 서면
                    또는 전자형식(모든 사본 포함)을 불문하고 전적으로 위원회의
                    재산이며, 고용 만료와 함께 즉각적으로 위원회에 반납되어야
                    한다.
                    <br />
                    ③ 이러한 의무는 위원회와의 계약관계가 종료된 후에도 계속
                    적용되어야 한다.
                    <br />
                    <br />
                    부칙(2011.7.22.)
                    <br />
                    제1조 (시행일) 이 강령은 공표일인 2011년 7월 22일부터
                    시행한다.
                    <br />
                    <br />
                    부칙(2014.12.24.)
                    <br />
                    제1조 (시행일) 이 강령은 공표일인 2014년 12월 24일부터
                    시행한다.
                    <br />
                    <br />
                    부칙(2018.10.30.)
                    <br />
                    제1조 (시행일) 이 강령은 공표일인 2018년 10월 30일부터
                    시행한다.
                    <br />
                    <br />
                    부칙(2019.3.12.)
                    <br />
                    제1조 (시행일) 이 강령은 공표일인 2019년 3월 12일부터
                    시행한다.
                  </p>
                </article>
              </div>
            </AccordionBody>
          </AccordionItem>
        </Accordion>
      </Container>
    </Section>
  </LayoutWithTitle>
);
export default Mission;
